import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { appName } from "../../config";
import useCasinoType from "../../hook/useCasinoType";
import { useLocation } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const CommonHelmet = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`seo.home.${key}`, { ...props });

  return process.env.REACT_APP_DEVELOP_MODE == 1 ? null : (
    <Helmet htmlAttributes={{ lang: i18next.language }}>
      <meta charSet="utf-8" />
      <meta property="og:description" content={appName} />
      <meta property="og:title" content={appName} />
      <meta property="og:url" content={window.location.origin} />
      <link
        rel="icon"
        href={`${process.env.REACT_APP_I18N_PATH}/logo/logo_144.png?ddd`}
      />
      <link
        rel="apple-touch-icon"
        href={`${process.env.REACT_APP_I18N_PATH}/logo/logo_144.png?ddd`}
      />
      <link rel="canonical" href={window.location.origin} />
      <title lang={i18next.language}>{i18n("title", { appName })}</title>
      <meta lang={i18next.language} content={i18n("meta1", { appName })} />
    </Helmet>
  );
};

export default CommonHelmet;
