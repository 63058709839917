import { AbsoluteCenter, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useMatches, useParams } from "react-router-dom";
import { routeMapping } from "./utils/routeMapping";
import { COMMON_WEB_PATH } from "../../../constant";
import { gameTypeColor } from "./utils/gameTypeColor";
import { exceptGameType } from "./utils/exceptGameType";

const GameTypeSelector = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, gameList } = isHandleAllGame || {};

  const splideRef = useRef();

  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType } = useParams();

  const gameSubTypeList = useMemo(() => {
    if (gameType) {
      const combileType = [...gameType];
      const convertType = combileType.map((item) => {
        return routeMapping(item)?.link;
      });
      return [
        ...combileType.map((item, index) => {
          const { link } = routeMapping(item);
          const current_game_type = routeMapping(item)?.origin;
          const platform_list = Object?.keys(gameList[current_game_type] || {});

          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <Link
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {(props) => {
                  return (
                    <Box position={`relative`}>
                      {props.isActive && (
                        <Box
                          position={"absolute"}
                          top="4px"
                          left="50%"
                          w="0"
                          h="0"
                          borderStyle={"solid"}
                          borderWidth={"7.5px 5px 0 5px"}
                          sx={{
                            borderColor:
                              "#CC9D35 transparent transparent transparent",
                          }}
                          zIndex={10}
                          transform="translate(-50%,-50%) rotate(0deg)"
                        ></Box>
                      )}
                      <Flex
                        bgGradient={
                          props.isActive
                            ? "linear(to-b,brand.100,brand.300,brand.300)"
                            : "linear(to-b,brand.300,brand.500,brand.800,brand.800)"
                        }
                        direction={`column`}
                        alignItems={`center`}
                        overflow={`hidden`}
                        position={`relative`}
                        borderRadius={`15px`}
                        color={`white`}
                        height={`70px`}
                        gap={`3px`}
                        justifyContent={`center`}
                        borderBottom="4px solid"
                        borderBottomColor="brand.1000"
                        p="1px"
                      >
                        {/* <AbsoluteCenter w={`100%`} h="100%">
                          <Image
                            w="100%"
                            h="100%"
                            src={`${COMMON_WEB_PATH}/b69-home/game-type-icon/${item}.svg`}
                          />
                        </AbsoluteCenter> */}
                        <Image
                          w="35px"
                          // h="35px"
                          position="relative"
                          src={`${COMMON_WEB_PATH}/67-casino/game-type-icon-v3/${item}_icon.webp`}
                        />
                        <Text
                          fontWeight={`600`}
                          fontSize={"12px"}
                          position="relative"
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList]);

  const findCurrentGameTypeIndex = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;

    const processGameType = gameType?.map((item) => {
      const textMapping = routeMapping(item);

      return textMapping?.link.replace("/", "");
    });

    return processGameType?.indexOf(mainType);
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  const splideOptions = useMemo(() => {
    return {
      start: findCurrentGameTypeIndex,
      pagination: false,
      arrows: false,
      drag: "free",
      direction: "ttb",
      height: "auto",
      // perPage: 5,
      // perMove: 1,
      gap: 0,
      lazyLoad: "nearby",
      fixedWidth: "70px",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
      breakpoints: {
        640: {
          fixedHeight: "70px",
          height: "350px",
        },
      },
    };
  }, [findCurrentGameTypeIndex]);

  useEffect(() => {
    if (splideRef.current) {
      const mainType = gameMainTypeFromProps || gameMainType;
      const splide = splideRef.current.splide;
      const processGameType = gameType?.map((item) => {
        const textMapping = routeMapping(item);
        return textMapping?.link.replace("/", "");
      });
      splide.go(processGameType?.indexOf(mainType));
    }
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  return (
    <Box
      borderRadius={"common"}
      overflow="hidden"
      // bgGradient={"linear(to-b,brand.500,brand.600,brand.300,brand.700)"}
      pt="3px"
      position="sticky"
      top={{
        base: "65px",
        md: "10px",
      }}
    >
      <Splide
        ref={splideRef}
        options={{
          start: findCurrentGameTypeIndex,
          pagination: false,
          arrows: false,
          drag: "free",
          direction: "ttb",
          // perPage: 5,
          // perMove: 1,
          gap: "10px",
          lazyLoad: "nearby",
          fixedWidth: "70px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          breakpoints: {
            mediaQuery: "min",
            640: {
              fixedHeight: "70px",
              height: "365px",
            },
            5000: {
              fixedHeight: "70px",
              height: "auto",
            },
          },
        }}
      >
        {gameSubTypeList}
      </Splide>
    </Box>
  );
};

export default GameTypeSelector;
