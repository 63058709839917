import { Fragment, useCallback, useMemo } from "react";
import TopTab from "../Home/components/topTab";
import MobileSearch from "../Search/mobile-search";
import GameCatalog from "../../components/GameCatalog";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useSubTypeList from "../../hook/useSubTypeList";
import SupplierList from "../../components/GameSelect/SupplierList";
import useCasinoType from "../../hook/useCasinoType";
import { filterType } from "../../hook/useGameListFilter";
import FavoriteGameCatalog from "../../components/FavoriteGameCatalog";
import LoadingForLiveLayout from "../../components/Loading/LoadingForLiveLayout";
import LobbyGame from "./components/lobbyGame";
import useGetGamePlatform from "../../hook/useGetGamePlatform";
import Ranking from "../../components/Ranking/Ranking";
import { Box } from "@chakra-ui/react";
import Seo from "./seo";

const Live = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame); //redux純取值用useSelector就好 不用dispatch
  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  const subList = useSubTypeList();

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { allGamePlatform = [] } = isHandleAllGame;

  //把Redux store 中的 isHandleAllGame 属性，儲存在 handleAllGame 變量中
  const { platform, topGameList, newGameList, categorizedGames } =
    handleAllGame; //從handleAllGame中提取了 platform、gameList 和 gameType 属性。可以在组件中直接使用这些属性，而不必每次都通过handleAllGame 来訪問它们。

  const platformList = useGetGamePlatform(); //調用 useGetGamePlatform 這個 Hook，並將返回的值賦值給 platformList

  const casinoType = useCasinoType();

  const location = useLocation();
  const { pathname } = location;

  const lobbyGames = useMemo(() => {
    if (!allGamePlatform) {
      return [];
    }

    return filterType({
      gameList: (categorizedGames?.Lobby || []).filter((item) => {
        const platformInfo = allGamePlatform.find(
          (p) => p.platform === item.game_platform
        );
        return platformInfo && !platformInfo.isUnderMaintenance;
      }),
      casinoType: casinoType,
    });
  }, [categorizedGames, allGamePlatform, casinoType]);

  const subGames = useMemo(() => {
    return subList?.map((subType, index) => {
      if (!allGamePlatform) {
        return null;
      }
      //過濾掉正在維護的遊戲
      const subGamelist = filterType({
        gameList: (categorizedGames[subType] || []).filter((item) => {
          const platformInfo = allGamePlatform.find(
            (p) => p.platform === item.game_platform
          );
          return platformInfo && !platformInfo.isUnderMaintenance;
        }),
        casinoType: casinoType,
      });
      return (
        <>
          {subGamelist?.length > 4 && (
            <>
              <GameCatalog
                list={subGamelist}
                wrapperClassName={subGamelist?.length === 0 && "hidden"}
                mainTitle={subType}
                typeTitle={``}
                observeFalse={index == 0}
              />
            </>
          )}
          {index === 4 && (
            <>
              <section className="px-common-padding-x mb-[20px]">
                <SupplierList
                  LIST={platformList}
                  customNavigate="/live-casino/category/"
                />
              </section>
            </>
          )}
        </>
      );
    });
  }, [categorizedGames, subList, allGamePlatform, casinoType]);

  return (
    <Fragment>
      <Seo />
      <Box
        display={{
          base: "block",
          md: "none",
        }}
      >
        <TopTab />
      </Box>
      <Box py="common-y"></Box>
      <Box
        display={{
          base: "block",
          md: "none",
        }}
      >
        <MobileSearch />
      </Box>
      <Box>
        {loadingStatus ? (
          <LoadingForLiveLayout itemClass="md:!h-[300px] h-[180px]" />
        ) : (
          <GameCatalog
            hiddenButtonNavigator
            list={lobbyGames}
            mainTitle={"Lobby"}
            typeTitle={``}
            itemClass="md:!h-[300px] h-[180px]"
            observeFalse
            swiperProps={{
              delay: 1,
              speed: 25000,
              swiperClassName: "custom-swiper-wrapper",
              customPerView: "live-layout",
            }}
            // customGameList={<LobbyGame />}
          />
        )}
      </Box>
      <Box>{subGames}</Box>
    </Fragment>
  );
};

export default Live;
