import { Splide, SplideSlide } from "@splidejs/react-splide";

import { stylesConfig } from "../helpers/stylesConfig";
import useDeviceType from "../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import LazyImage from "./Loading/lazyImage";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import useProcessEventList from "../hook/useProcessEventList";
import { useMemo } from "react";
import { DATA2_PATH } from "../constant";
import i18next from "i18next";
import { languageMapping } from "../i18n/config";
import useProcessCarouselList from "../hook/useProcessCarouselList";
import dayjs from "dayjs";

const CarouselV3 = () => {
  const { i18n } = useTranslation();

  const isMobile = useDeviceType() === "Mobile";
  const eventList = useSelector((state) => state.isEventList);
  const carouselList = useSelector((state) => state.isCarouselList);

  const processEventList = useProcessEventList({ event_list: eventList });
  const processCarouselList = useProcessCarouselList({
    carousel_list: carouselList,
  });
  const filter_event_list = useMemo(() => {
    return processEventList?.filter((item) => item?.is_show_carousel == 1);
  }, [processEventList]);
  return (
    <Box borderRadius={`common`}>
      <Splide
        className="h-full w-full v2"
        options={{
          type: "slide",
          rewind: true,
          // perPage: 2,
          gap: 10,
          autoplay: true,
          arrows: false,
          breakpoints: {
            640: {
              perPage: 1,
              // fixedWidth: "95%",
            },
            768: {
              perPage: 1,
            },
            1024: {
              perPage: 2,
            },
            3000: {
              perPage: 2,
            },
          },
        }}
      >
        {processCarouselList?.map((item) => {
          const { uid, image_mobile, image_pc, process_url_link } = item || {};
          const lang = languageMapping(i18next.language);
          return (
            <SplideSlide key={uid}>
              <Link to={process_url_link}>
                <Box
                  height={{
                    sm: "200px",
                    base: "110px",
                  }}
                  width="100%"
                  borderRadius={`common`}
                  overflow="hidden"
                  border="1px solid"
                  // borderColor="leaderBoard.v3-border"
                  borderColor="brand.500"
                >
                  <Box
                    display={{
                      base: "block",
                      lg: "none",
                    }}
                    w="100%"
                    h="100%"
                  >
                    <LazyImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${DATA2_PATH}/carousels/${image_mobile}?${dayjs().format(
                          "YYYYMMDDHHmmss"
                        )}`;
                      }}
                      src={`${DATA2_PATH}/carousels/${image_mobile}?${dayjs().format(
                        "YYYYMMDDHHmmss"
                      )}`}
                      closeInverted
                      fetchPriority="high"
                      alt={item?.title}
                      className={`object-cover h-full !w-full object-cover `}
                    />
                  </Box>
                  <Box
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                    w="100%"
                    h="100%"
                  >
                    <LazyImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${DATA2_PATH}/carousels/${image_pc}?${dayjs().format(
                          "YYYYMMDDHHmmss"
                        )}`;
                      }}
                      src={`${DATA2_PATH}/carousels/${image_pc}?${dayjs().format(
                        "YYYYMMDDHHmmss"
                      )}`}
                      closeInverted
                      fetchPriority="high"
                      alt={item?.title}
                      className={`object-cover h-full !w-full object-cover `}
                    />
                  </Box>
                </Box>
              </Link>
            </SplideSlide>
          );
        })}
        {filter_event_list?.map((item) => {
          const { uid } = item || {};
          const lang = languageMapping(i18next.language);

          return (
            <SplideSlide key={uid}>
              <Link to={`/promotion/detail/${uid}`}>
                <Box
                  height={{
                    sm: "200px",
                    base: "110px",
                  }}
                  width="100%"
                  borderRadius={`common`}
                  overflow="hidden"
                  border="1px solid"
                  // borderColor="leaderBoard.v3-border"
                  borderColor="brand.500"
                >
                  <Box
                    display={{
                      base: "block",
                      lg: "none",
                    }}
                    w="100%"
                    h="100%"
                  >
                    <LazyImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_mobile_image.png?${dayjs().format(
                          "YYYYMMDDHHmmss"
                        )}`;
                      }}
                      src={`${DATA2_PATH}/event/${uid}/${lang}/${uid}_${lang}_mobile_image.png?${dayjs().format(
                        "YYYYMMDDHHmmss"
                      )}`}
                      closeInverted
                      fetchPriority="high"
                      alt={item?.title}
                      className={`object-cover !h-full !w-full object-contain `}
                    />
                  </Box>
                  <Box
                    display={{
                      base: "none",
                      lg: "block",
                    }}
                    w="100%"
                    h="100%"
                  >
                    <LazyImage
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_pc_image.png?${dayjs().format(
                          "YYYYMMDDHHmmss"
                        )}`;
                      }}
                      src={`${DATA2_PATH}/event/${uid}/${lang}/${uid}_${lang}_pc_image.png?${dayjs().format(
                        "YYYYMMDDHHmmss"
                      )}`}
                      closeInverted
                      fetchPriority="high"
                      alt={item?.title}
                      className={`object-cover !h-full !w-full object-contain `}
                    />
                  </Box>
                </Box>
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
};

export default CarouselV3;
