import { useEffect, useState } from "react";
import {
  motion,
  isValidMotionProp,
  AnimatePresence,
  useWillChange,
} from "framer-motion";

import { useNavigate } from "react-router";
import { getFixedStyle } from "../../hook/useDeviceType";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  chakra,
  Button,
  shouldForwardProp,
  useColorModeValue,
  Icon,
  Image,
  Text,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import SignupForm from "./signupForm";
import PromoCardMobile from "../Singin/components/promoCard_mobile";
import PromoCardPc from "../Singin/components/promoCard_pc";
import useCheckLogin from "../../hook/useCheckLogin";
import { resetInvalidAuth } from "../../redux/action/member/memberAction";
import { useDispatch } from "react-redux";
import { MdOutlineArrowBack } from "react-icons/md";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import SignupFormV2 from "./signupForm_v2";
import { popFromBottom } from "../../animateConstant";
import CreditPage from "../../utils/credit/credit_page";
import SignupFormV3 from "./signupForm_v3";
import { IoClose } from "react-icons/io5";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SignupV3 = () => {
  const { t } = useTranslation();
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const navigate = useNavigate();
  const previousPage = usePreviousPagePath();

  const dispatch = useDispatch();

  const baseBg = useColorModeValue("light.100", "dark.100");
  const mainBg = useColorModeValue("light.200", "dark.200");

  const willChange = useWillChange();

  const { isLogin } = useCheckLogin();

  useEffect(() => {
    dispatch(resetInvalidAuth());

    if (isLogin) {
      navigate("/");
    }
  }, []);

  return (
    <ChakraBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ willChange }}
      display="flex"
      flexDirection={`column`}
      justifyContent={`start`}
      alignItems="center"
      zIndex="10000"
      py={{
        base: "20vh",
        md: "20vh",
      }}
      px="10px"
      width={{
        base: "100%",
      }}
      height={{
        base: "100%",
      }}
      overflowY={`scroll`}
      position="fixed"
      top={`0px`}
      left={`0px`}
      className={`lg:backdrop-blur-none backdrop-blur-md no-scrollbar`}
    >
      <Box
        width={{
          md: "400px",
          base: "100%",
        }}
        height={{
          base: "auto",
          md: "auto",
        }}
        borderRadius={{
          base: "20px",
          md: "20px",
        }}
        // borderTopRadius={{
        //   md: "none",
        // }}
        // borderBottomRadius={{
        //   md: "common",
        // }}
        shadow="md"
        bg={"brand.700"}
        borderBottom="7px solid"
        borderColor="brand.900"
        pt="20px"
        position="relative"
        p="15px"
        pb="60px"
        // overflow="hidden"
      >
        <Box
          as={Link}
          zIndex={1}
          position="absolute"
          top="-10px"
          right="-10px"
          to={"../"}
        >
          <Box
            w="46px"
            h="46px"
            borderRadius={"full"}
            bgGradient={"linear(to-r,brand.500,brand.900)"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              bgGradient={"linear(to-r,red.600,red.700)"}
              borderRadius={"full"}
              border="1px solid"
              borderColor="leaderBoard.v3-border"
            >
              <Icon as={IoClose} color="light.100" fontSize="32px" />
            </Box>
          </Box>
        </Box>
        <AbsoluteCenter
          zIndex={1}
          px="40px"
          py="10px"
          bgGradient={"linear(to-r,brand.500,brand.900)"}
          borderRadius={"common"}
          borderBottom="4px solid"
          color="white"
          borderColor="brand.700"
          top="-0%"
          fontWeight={"900"}
          fontSize="20px"
        >
          SIGN UP
        </AbsoluteCenter>

        {/* <Box
          display="flex"
          justifyContent={`space-between`}
          mb="50px"
          px="20px"
        >
          <Link to={"../"}>
            <Icon as={MdOutlineArrowBack} color="light.100" fontSize="32px" />
          </Link>

          <Image
            src={stylesConfig.mainLogo}
            cursor={"pointer"}
            // mr={{
            //   base: "0px",
            //   lg: "45px",
            // }}
            // ml={{
            //   base: "0px",
            //   lg: "25px",
            // }}
            width={
              isLogin
                ? {
                    md: "80px",
                    base: "100px",
                  }
                : {
                    md: "105px",
                    base: "100px",
                  }
            }
            pointerEvents={"all"}
            alt={COMMON_ALT}
          />
        </Box> */}

        <Box borderRadius={`common`}>
          {/* <Flex justifyContent={`center`} alignItems={`center`} mb="10px">
            <Flex gap="80px">
              <NavLink to="../signin">
                {({ isActive }) => {
                  return (
                    <Box
                      py="10px"
                      position="relative"
                      color={isActive ? "special.gold" : ""}
                      fontSize="20px"
                      _after={{
                        content: '""',
                        position: "absolute",
                        top: "90%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "120%",
                        height: "2px",
                        bg: isActive ? "special.gold" : "",
                      }}
                    >
                      <Text>{i18n_header("signin")}</Text>
                    </Box>
                  );
                }}
              </NavLink>
              <CreditPage>
                <NavLink to="../signup">
                  {({ isActive }) => {
                    return (
                      <Box
                        py="10px"
                        position="relative"
                        color={isActive ? "special.gold" : ""}
                        fontSize="20px"
                        _after={{
                          content: '""',
                          position: "absolute",
                          top: "90%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          width: "120%",
                          height: "2px",
                          bg: isActive ? "special.gold" : "",
                        }}
                      >
                        <Text>{i18n_header("signup")}</Text>
                      </Box>
                    );
                  }}
                </NavLink>
              </CreditPage>
            </Flex>
          </Flex> */}

          <ChakraBox
            {...popFromBottom}
            width="100%"
            py="40px"
            px="30px"
            h={{
              base: "-webkit-fit-content",
              md: "auto",
            }}
            className="no-scrollbar"
            display="flex"
            flexDirection={`column`}
            borderTopLeftRadius={`120px`}
            bg="#FDFCFB"
            borderRadius={"common"}
            borderBottom="7px solid"
            borderColor="#D9C969"
          >
            <SignupFormV3 />
          </ChakraBox>
        </Box>
      </Box>
    </ChakraBox>
  );
};

export default SignupV3;
