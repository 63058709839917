import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import GetParams from "../../utils/getParams";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH } from "../../constant";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

import useDeviceType from "../../hook/useDeviceType";
import LazyImage from "../Loading/lazyImage";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/layout";
import { useColorModeValue, useColorMode } from "@chakra-ui/color-mode";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import useGetFullGamePlatform from "../../hook/useGetFullGamePlatform";
import { IoLogoGameControllerB } from "react-icons/io";
import { Icon, Flex } from "@chakra-ui/react";

const SupplierList = ({
  dontFilter,
  LIST,
  setPlatform = () => {},
  customNavigate,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { allGamePlatform = [], sortedByPlatformGames = {} } = isHandleAllGame;

  const getFullPlatform = useGetFullGamePlatform();

  const textColor = useColorModeValue("dark.1200", "light.100");
  const boxBg = useColorModeValue("light.200", "dark.100");
  const { colorMode } = useColorMode();

  const filteredList = useMemo(() => {
    return dontFilter
      ? LIST
      : LIST?.filter((item) => {
          const platformInfo = allGamePlatform.find((p) => p.platform === item);
          return platformInfo && !platformInfo.isUnderMaintenance;
        });
  }, [LIST, dontFilter]);

  const ref = useRef(null);

  const { ref: containerRef, inView } = useInView({
    threshold: 0.75,
    rootMargin: "0px 0px 0px 0px",
  });

  useEffect(() => {
    if (inView) {
      ref.current.splide.Components.AutoScroll.play();
    } else {
      ref.current.splide.Components.AutoScroll.pause();
    }
  }, [inView]);

  const navigate = useNavigate();

  const currentPlatform = GetParams("platform");

  return (
    <>
      <Text as="p" fontSize="xl" fontWeight="bold" mb="10px" color={textColor}>
        {i18n("supplier")}
      </Text>
      <Box as="section" ref={containerRef}>
        <Splide
          ref={ref}
          extensions={{ AutoScroll }}
          options={{
            type: "slide", //不讓它loop 因有補齊項目數問題
            pagination: false,
            arrows: false,
            drag: "free",
            perPage: 7,
            lazyLoad: "nearby",
            preloadPages: 1,
            breakpoints: {
              1440: {
                perPage: 6,
              },
              1280: {
                perPage: 6,
              },
              768: {
                perPage: 5,
              },
              640: {
                perPage: 2,
              },
            },
            perMove: 1,
            gap: 20,
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            keyboard: false,
          }}
        >
          {filteredList?.map((item, index) => {
            const platformGameNumber = sortedByPlatformGames[item]?.length || 0;
            return (
              <SplideSlide key={item}>
                <Box
                  role="group"
                  onClick={() => {
                    if (customNavigate) {
                      navigate(`${customNavigate}${item}/AllGames`);
                    } else {
                      navigate(
                        `/${getFullPlatform[item]}/category/${item}/AllGames`
                      );
                    }
                    setPlatform(item);
                  }}
                  display="flex"
                  h="50px"
                  alignItems="center"
                  justifyContent="center"
                  py="5px"
                  px="15px"
                  borderTopRadius={`common`}
                  cursor={`pointer`}
                  bg={boxBg}
                >
                  <LazyImage
                    className={`w-[100px] h-full object-contain mx-auto ${
                      currentPlatform === item && "grayscale-0"
                    }`}
                    src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                      item,
                      colorMode === "dark"
                    )}.png`}
                    alt={item}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems={`center`}
                  justifyContent={`center`}
                  borderBottomRadius={`common`}
                  bg="brand.500"
                  p="1.5px"
                  color="white"
                >
                  <Flex gap="5px" fontSize="sm" alignItems="center">
                    <Icon as={IoLogoGameControllerB} />
                    <Text>{platformGameNumber}</Text>
                  </Flex>
                </Box>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </>
  );
};

export default SupplierList;
