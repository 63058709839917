import { Button, ButtonGroup, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

const ButtonTab = ({ setTab, tab, TABS }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabBg = useColorModeValue("light.100", "dark.100");
  return (
    <ButtonGroup
      isAttached
      w="100%"
      bg={tabBg}
      overflow="hidden"
      borderRadius={`common`}
    >
      {TABS?.filter((item) => !item.hidden)?.map((item) => (
        <Button
          key={item.label}
          onClick={() => {
            if (typeof setTab === "function") {
              setTab(item.value);
            }
            setSearchParams({ tab: item.value });
          }}
          w={"100%"}
          className="text-overflow"
          bg={tab === item.value ? "brand.500" : ""}
          _hover={tab === item.value ? "brand.500" : ""}
          _active={tab === item.value ? "brand.500" : ""}
          color={tab === item.value ? "white" : "special.1"}
        >
          <Text className="text-overflow">{item.label}</Text>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonTab;
