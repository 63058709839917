import { parseSomething } from "../utils/parseSomething";

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        "67-main": {
          leaderBoard: {
            left: "#a4872a",
            right: "#bb9a30",
            border: "#8c7424",
            avatar: "#d3b552",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#f1e7c7",
            200: "#ebddaf",
            300: "#e5d398",
            400: "#dfc981",
            500: "#d3b552",
            600: "#cdab3b",
            700: "#bb9a30",
            800: "#af912d",
            900: "#a4872a",
            950: "#987e27",
            1000: "#8c7424",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(211, 181, 82, 0.4)",
            "text-mask-from": "#dfc981",
          },
          "second-brand": {
            500: "#a1110f",
            700: "#c11412",
          },
          "right-promotion-box": {
            to: "#d9bf69",
            from: "#dfc981",
            border: "#cdab3b",
            hover_to: "#d3b552",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#d9bf69",
          },
          "v3-second-brand": {
            100: "#f3afaf",
            200: "#ef9797",
            300: "#ec7f7f",
            400: "#e86767",
            500: "#e13636",
            600: "#da2121",
            700: "#c21d1d",
            800: "#b61b1b",
            900: "#aa1919",
            950: "#9e1818",
            1000: "#921616",
            "500-opacity": "#921616",
            "700-opacity": "#791212",
          },
          "v3-profile": {
            "banner-from": "#FEFEFD",
            "banner-to": "#F2F0DF",
            "banner-border": "#9D6C1D",
            "banner-title": "#F5F3E6",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#2d250c",
            150: "#171306",
            200: "#231d09",
            250: "#bb9a30",
            300: "#cdab3b",
            400: "#d9bf69",
            500: "#d3b552",
            600: "#dfc981",
            700: "#ebddaf",
          },
        },
      }
    : parseSomething(window.web_style_config);
