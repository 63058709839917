import { Box, Flex } from "@chakra-ui/react";
import React, { Fragment, useEffect } from "react";
import GameTypeSelector from "../../components/v3Layout/GameTypeSelector/GameTypeSelector";
import SubListSelector from "../../components/v3Layout/SubListSelector/SubListSelector";
import JackpotWrapper from "../../components/v3Layout/Jackpot/JackpotWrapper";
import PlatformSelector from "../../components/v3Layout/PlatformSelector/PlatformSelector";
import GameSelector from "../../components/v3Layout/GameSelector/GameSelector";
import MobileSearchV2 from "../Search/mobile-search_v2";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import CarouselV2 from "../../components/Carousel_v2";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ExceptWrapper from "./utils/exceptWrapper";
import CarouselV3 from "../../components/Carousel_v3";

const GamePlatformPageV3 = () => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, topGameList, newGameList } = isHandleAllGame;

  const { gameMainType } = useParams();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (gameMainType) {
  //     const current_type = routeMapping(gameMainType)?.origin;
  //     if (!gameType?.includes(current_type)) {
  //       return navigate("/slots");
  //     }
  //   }
  // }, [gameType, gameMainType]);
  return (
    <Fragment>
      <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box as="section" py="home-space" px="common-y">
          <CarouselV3 />
        </Box>
      </ExceptWrapper>
      <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box as="section" pb={{ md: "0", base: "home-space" }} px="common-y">
          <MarqueeListV2 />
        </Box>
      </ExceptWrapper>
      <Box
      // mt={{
      //   md: "0px",
      //   base: "10px",
      // }}
      >
        {/* <MobileSearchV2 listHidden /> */}
      </Box>
      <Flex>
        <Box
          // py={{
          //   md: "home-space",
          //   base: "0",
          // }}
          pl="common-y"
        >
          <GameTypeSelector />
        </Box>

        <Box
          overflowY="scroll"
          flex={1}
          id="scrollableDiv"
          h="100%"
          // py={{
          //   md: "home-space",
          //   base: "0",
          // }}
          // h={{
          //   base: "80vh",
          //   md: "auto",
          // }}
        >
          <Box py="home-space" px="common-y">
            <PlatformSelector />
          </Box>

          {/* <Box py="home-space" px="common-y">
        <LeaderBoard />
      </Box>
       <Box mt="home-space" px="common-y">
        <JackpotWrapper />
      </Box> */}
          {/* <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box px="common-y">
          <GameCatalogV2
            wrapperClassName={`!px-[0px]`}
            list={topGameList}
            mainTitle={`Top`}
            // swiperProps={{
            //   customPerView: "popular-layout",
            // }}
            isHot
            customFixedWidth="160px"
          />
        </Box>{" "}
      </ExceptWrapper> */}

          {/* <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box px="common-y">
          <GameCatalogV2
            wrapperClassName={`!px-[0px]`}
            list={newGameList}
            mainTitle={`News`}
            // swiperProps={{
            //   customPerView: "popular-layout",
            // }}
            isHot
            customFixedWidth="160px"
          />
        </Box>
      </ExceptWrapper> */}

          <Box py="home-space" px="common-y">
            {/* <SubListSelector /> */}
            <GameSelector subListSelector />
          </Box>
        </Box>
      </Flex>
    </Fragment>
  );
};

export default GamePlatformPageV3;
