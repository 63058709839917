const parseSomething = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
};

const communication_url = parseSomething(window.communication_url);

export const community_link = {
  facebook: communication_url?.Facebook || "",
  instagram: communication_url?.Instagram || "",
  youtube: communication_url?.Youtube || "",
  twitter: communication_url?.Twitter || "",
  tiktok: communication_url?.Tiktok || "",
  messager: communication_url?.Messager || "",
  telegram: communication_url?.Telegram || "",
};
