import React, { Fragment, useMemo } from "react";
import Seo from "../Slot/seo";
import CarouselV3 from "../../components/Carousel_v3";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import MobileSearchV2 from "../Search/mobile-search_v2";
import { Box, Flex } from "@chakra-ui/react";
import JackpotWrapper from "../../components/v3Layout/Jackpot/JackpotWrapper";
import LeaderBoard from "../../components/v3Layout/LeaderBoard/LeaderBoard";
import SubListSelector from "../../components/v3Layout/SubListSelector/SubListSelector";
import PlatformSelector from "../../components/v3Layout/PlatformSelector/PlatformSelector";
import GameTypeSelector from "../../components/v3Layout/GameTypeSelector/GameTypeSelector";
import GameCatalogV3 from "../../components/GameCatalog_v3";
import { useSelector } from "react-redux";
import useSubTypeListV2 from "../../hook/useSubTypeList_v2";
import { filterType } from "../../hook/useGameListFilter";
import { filterTypeV2 } from "../../hook/useGameListFilter_v2";
import EventCarousel from "./components/event_carousel";

const HomeV3 = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);

  const {
    platform,
    topGameList,
    newGameList,
    categorizedGames,
    exclusiveBrandGames,
    gameListByTypeAndSubType,
  } = handleAllGame;

  const subList = useSubTypeListV2({
    forceShowAll: true,
    gameMainTypeFromProps: "SLOT",
  });

  const subGames = useMemo(() => {
    const slot_game_list = gameListByTypeAndSubType?.["SLOT"];
    return Object?.keys(slot_game_list || {})?.map((sub_type, index) => {
      const subGamelist = slot_game_list[sub_type];
      return (
        subGamelist?.length > 0 && (
          <Fragment key={sub_type}>
            <GameCatalogV3
              list={subGamelist}
              //   wrapperClassName={subGamelist?.length === 0 && "hidden"}
              mainTitle={sub_type}
              observeFalse
              swiperProps={{
                customPerView: "v3-normal-layout",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
          </Fragment>
        )
      );
    });
  }, [gameListByTypeAndSubType, subList, currentCurrency]);
  return (
    <Fragment>
      <Seo />
      <Box as="section" pt="home-space" px="common-y">
        <CarouselV3 />
      </Box>
      <Box as="section" px="common-y">
        <MarqueeListV2 />
      </Box>
      <Box
        mt={{
          md: "0px",
          // base: "10px",
        }}
      >
        <MobileSearchV2 listHidden />
      </Box>
      <Flex>
        <Box
          py={{
            // md: "home-space",
            base: "0",
          }}
          pl="common-y"
          pr="5px"
        >
          <GameTypeSelector gameMainTypeFromProps={"SLOT"} />
        </Box>
        <Box
          overflowY="scroll"
          flex={1}
          // h={{
          //   base: "80vh",
          //   md: "auto",
          // }}
        >
          <Box py="home-space" px="common-y">
            <LeaderBoard />
          </Box>

          <Box my="home-space" px="common-y">
            <JackpotWrapper />
          </Box>
          <Box>
            <GameCatalogV3
              list={topGameList}
              wrapperClassName={topGameList?.length === 0 && "hidden"}
              mainTitle={"Top"}
              observeFalse
              swiperProps={{
                customPerView: "popular-layout",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
            <EventCarousel />
            <GameCatalogV3
              list={newGameList}
              wrapperClassName={newGameList?.length === 0 && "hidden"}
              mainTitle={"newGame"}
              observeFalse
              swiperProps={{
                customPerView: "new-game-layout",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
            <GameCatalogV3
              list={exclusiveBrandGames}
              wrapperClassName={exclusiveBrandGames?.length === 0 && "hidden"}
              mainTitle={"ExclusiveGame"}
              observeFalse
              swiperProps={{
                customPerView: "v3-normal-normal",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
            {subGames}
          </Box>
          {/* <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={topGameList}
              mainTitle={`Top`}
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              observeFalse
              customFixedWidth="155px"
            />
          </Box>{" "}
          <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={newGameList}
              mainTitle={`News`}
              customHotTag={{
                color: "linear(to-r,#9DF87B,#0E9117)",
                text: (
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    New
                  </Text>
                ),
              }}
              observeFalse
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              customFixedWidth="155px"
            />
          </Box> */}
          {/* <Box py="home-space" px="common-y">
            <GameSelector
              hiddenTitle
              gameMainTypeFromProps={gameMainTypeFromProps}
            />
          </Box> */}
        </Box>
      </Flex>
    </Fragment>
  );
};

export default HomeV3;
